<template>
  <div class="main_style">
    <div class="header">
      <div class="header-text">
        <p class="header-title">众享区块链扶贫方案</p>
        <p class="header-desc">
          将扶贫信息记录在区块链上，利用区块链实现扶贫款项的流转，保证了扶贫信息的公开透明、可追溯，从而实现专项专用，精准扶贫。
        </p>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="intro">
      <div class="commom-title">方案介绍</div>
      <div class="intro-content">
        <p>
          区块链扶贫将传统的人工管理、资金管理方式与区块链技术应用有机结合，让扶贫款项沿着规定的用途、条件、时间安全、透明、精准地下发。此举既保证了扶贫款项使用过程中的规范性、合理性，又简化了扶贫基金的使用流程，实现扶贫资金的下拨不走样、不截留、不挪用，遏制了扶贫造假等现象，有效避免人为因素导致帮扶资金过多消耗在“路上”，从而提高扶贫款项使用效率。在扶贫过程中，每一笔扶贫资金的使用都会在链上留痕，使扶贫款项的运作更加透明化，为扶贫资金的监督管理创造了有利条件。
        </p>
      </div>
    </div>
    <!-- 产品架构 -->
    <div class="framework">
      <div class="commom-title">方案架构</div>
      <div class="framework-content">
        <img src="https://image.peerfintech.cn/peerfintech_website_v2/solution/poverty/framework.png" />
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="advantage">
      <div class="commom-title">受益角色</div>
      <div class="advantage-content">
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img src="@/assets/img/solution/poverty/role-1.png" />
              </div>
              <div class="title">扶贫方</div>
              <div class="text">
                可直接将拨款给指定的人或机构，实现款项定向下发
              </div>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img src="@/assets/img/solution/poverty/role-2.png" />
              </div>
              <div class="title">监管方</div>
              <div class="text">
                扶贫款项的每一次流转都会直接记录在链上，不可造假，不可篡改。监管方可实时追溯钱款流向，减轻监管负担
              </div>
            </div></el-col
          >
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img src="@/assets/img/solution/poverty/role-3.png" />
              </div>
              <div class="title">扶贫对象</div>
              <div class="text">
                区块链一定程度上保证了钱款各个环节流通的公开性、透明性与真实性，有效助力帮扶对象快速、公平的拿到帮扶资金
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    // 页面回退顶部
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.main_style {
  background-color: #ffffff;
  .content-width {
    width: 1200px;
    margin: 0px auto;
  }
  .commom-title {
    padding: 70px 0px 50px;
    font-size: 32px;
    font-family: "PingFangSC-Medium";
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    text-align: center;
  }
  .header {
    width: 100%;
    height: 540px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/solution/poverty/bg.png")
      center/cover no-repeat;
    .header-text {
      @extend .content-width;
      .header-title {
        font-size: 36px;
        font-family: "PingFangSC-Medium";
        color: #000000;
        line-height: 50px;
        padding-top: 160px;
      }
      .header-desc {
        width: 510px;
        color: rgba(0,0,0,0.75);
        font-size: 20px;
        line-height: 33px;
        margin-top: 36px;
      }
    }
  }
  .intro {
    .intro-content {
      @extend .content-width;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 25px;
    }
  }
 
  .framework {
    padding-bottom: 70px;
    .framework-content {
      @extend .content-width;
      img {
        width: 100%;
      }
    }
  }
  .advantage {
    background: #f4f7fc;
    .advantage-content {
      @extend .content-width;
      padding-bottom: 70px;
      .el-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .grid-content {
        height: 370px;
        background: #ffffff;
        box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.1);
        padding: 40px 48px 0px 40px;
        .img {
          width: 124px;
          height: 124px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          padding: 24px 0px;
          font-size: 22px;
          font-family: "PingFangSC-Medium";
          color: rgba(0, 0, 0, 0.85);
          line-height: 30px;
        }
        .text {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 22px;
        }
      }
    }
  }
  .project {
    .project-content {
      @extend .content-width;
      padding-bottom: 70px;
    }
  }
}
</style>
